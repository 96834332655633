// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/($lang).auth.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/($lang).auth.tsx");
  import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { redirect, json } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import { getUserFromSession } from '~/api/utilities/authSession.server';
import { APP_PAGES_URL } from '~/lib/constants';
import PublicLayout from '~/components/_layout/Public/PublicLayout';
export const loader = async ({
  request
}) => {
  const user = await getUserFromSession(request);
  if (user?.id) {
    if (user?.verified) {
      return redirect(APP_PAGES_URL.APP);
    }
    return json({
      isLoggedIn: true
    });
  }
  return json({
    isLoggedIn: !!user?.id
  });
};
const AuthLayout = () => {
  return <PublicLayout>
      <main className='block w-full min-h-[calc(100vh-6.75rem)] container mx-auto p-4'>
        <Outlet />
      </main>
    </PublicLayout>;
};
_c = AuthLayout;
export default AuthLayout;
var _c;
$RefreshReg$(_c, "AuthLayout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;